// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-event-js": () => import("./../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-collaborator-js": () => import("./../src/templates/collaborator.js" /* webpackChunkName: "component---src-templates-collaborator-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-collaborators-js": () => import("./../src/pages/collaborators.js" /* webpackChunkName: "component---src-pages-collaborators-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meal-index-js": () => import("./../src/pages/meal-index.js" /* webpackChunkName: "component---src-pages-meal-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-press-js": () => import("./../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-studio-js": () => import("./../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */)
}

